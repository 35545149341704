<template lang="">
  <div>
    <!-- Start Filter -->
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label="Status"
          >
            <b-form-select
              v-model="active"
              :options="[
                { value: 'request', text: 'Permintaan Perawatan' },
                { value: 'plan', text: 'Permintaan Perawatan Diterima' },
                { value: 'realisation', text: 'Perawatan Sedang Diproses' },
                { value: 'reject', text: 'Permintaan Perawatan Ditolak' },
                { value: 'done', text: 'Perawatan Selesai' },
              ]"
              @input="changeSelectStatus"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            class="mr-1"
            variant="outline-secondary"
            type="button"
            @click.prevent="reset"
          >Reset</b-button>
          <b-button
            class="mr-1"
            variant="primary"
            type="button"
            @click.prevent="getFilter"
          >Filter</b-button>
        </b-col>
      </b-row>
    </b-card>
    <!-- End Of Filter -->
    <b-card v-if="renderComp">
      <basetable
        ref="basetable"
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :custom_filter="custom_filter"
        :is-detail="isDetail"
        :is-add="true"
        :is-edit="false"
        :is-delete="false"
      >
        <template v-slot:buttonaddon>
          <b-button
            class="float-right"
            variant="info"
            @click="showFilter =!showFilter"
          >
            <feather-icon
              icon="FilterIcon"
            />
            Filter
          </b-button>
        </template>
        <template #cell(status)="data">
          <span
            class="badge " :class="dataStatus[data.item.status].color"
          >
            {{ dataStatus[data.item.status].caption}}
          </span>
        </template>
        <template #cell(plan_km)="data">
          {{data.item.plan_km | numFormat}}
        </template>
        <template #cell(realisation_cost)="data">
          {{data.item.realisation_cost | numFormat}}
        </template>
        <template #cell(realisation_km)="data">
          {{data.item.realisation_km | numFormat}}
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol } from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    BFormGroup,

    BFormSelect,
    BRow,
    BCol,
  },
  data() {
    return {
      dataurl:"/maintenance?filter[status]=request",
      baseroute:"rencana-perawatan",
      title:"Daftar Rencana Perawatan",
      fields: [
        { key: 'code', label: 'Code', sortable: true },
        { key: 'vehicle.number', label: 'Nomor Polisi', sortable: true },
        { key: 'description', label: 'Deskripsi', sortable: true },
        { key: 'plan_km', label: 'KM Rencana', sortable: true },
        { key: 'plan_date', label: 'Tanggal Rencana', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        // { key: 'plan_cost', label: 'Rencana Biaya', sortable: true },
        { key: 'realisation_date', label: 'Tanggal Realisasi', sortable: true },
        { key: 'realisation_cost', label: 'Biaya Realisasi', sortable: true },
        { key: 'realisation_km', label: 'Km Realisasi', sortable: true }
      ],
      dataStatus:{
        request : {caption: 'Permintaan Perawatan' , color: 'bg-warning'},
        plan : {caption: 'Permintaan Perawatan Diterima' , color: 'bg-info'},
        realisation : {caption: 'Perawatan Sedang Diproses' , color: 'bg-success'},
        reject : {caption: 'Permintaan Perawatan Ditolak' , color: 'bg-danger'},
        done: {caption: 'Perawatan Selesai' , color: 'bg-success'},
        generated: {caption: 'Generate' , color: 'bg-secondary'},
      },
      showFilter : false,
      filter :[],
      active:null,
      custom_filter : true,
      isDetail : true,
      renderComp : true,
      isDelete : false,
      isEdit : false,
    }
  },
  created() {
      if (this.$store.state.profile.info) {
        this.userType =  this.$store.state.profile.info.contact_type.split('\\').pop().toLowerCase()
        this.user = this.$store.state.profile.info.contact
      }
      this.dataurl = this.userType == 'vendor' ? this.dataurl+"&contact="+this.user.id : this.dataurl
  },
  methods:{
    show(){
      // this.filter['filter[active]']=this.active
      // this.filter['filter[region_id]']=this.region.id
      this.$refs.basetable.fetchData()
    },
    getFilter(){
        this.renderComp = false
        this.$nextTick().then(() => {
            this.renderComp = true
        })
    },
    changeSelectStatus(data){
        if(data != null){
            this.filter['filter[status]'] = data
        }
    },
    reset(){
      this.active = null
      this.filter=[]
      this.renderComp = false
        this.$nextTick().then(() => {
            this.renderComp = true
        })
    }
  }
}
</script>
<style lang="">

</style>
